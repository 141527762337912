import { Divider, Modal } from 'rsuite';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { Schedule } from 'src/core/types';

import {
  FLAG_ENABLE_INCLUDE_ATTACHMENTS,
  FLAG_ENABLE_IS_READ_RECEIPT_CHECKBOX,
  MixpanelEvent
} from 'src/core/flags';

import {
  ChatButton,
  ChatCheckbox,
  Render,
  useTrack
} from 'src/core';

import {
  ChatSelectStyled,
  ChatTextAreaStyled,
  ChatTitleStyled,
  SubmitContainerStyled,
  VerticalSpacerStyled
} from './styles';

export interface MessageCreationModalResult {
  selected: Schedule[];
  selectedLabel: string[];
  message: string;
  isReadReceiptEnabled: boolean;
}

export interface MessageCreationModalProps {
  isOpen: boolean;
  data: Schedule[];
  onSubmit: (result: MessageCreationModalResult) => void;
  onClose: () => void;
}

export const MessageCreationModal: FunctionComponent<MessageCreationModalProps> = ({
  isOpen,
  data,
  onSubmit,
  onClose
}) => {
  const track = useTrack();

  const scheduleChannelOptions = useMemo(() => {
    const options = data.map((scheduleChannel) => {
      return {
        label: scheduleChannel.name,
        value: scheduleChannel,
      };
    });
    options.unshift({
      label: "Todas as escalas",
      value: {
        id: 1,
        name: "Todas as escalas",
        folder_id: 0,
        folder_name: "",
      },
    });
    return options;
  }, [data]);

  const [message, setMessage] = useState<string>("");
  const [scheduleChannels, setScheduleChannels] = useState<Schedule[]>([]);
  const [isReadReceiptEnabled, setIsReadReceiptEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (!isOpen) {
      setMessage("");
      setScheduleChannels([]);
      setIsReadReceiptEnabled(false);
    }
  }, [isOpen]);

  const onClickSubmit = useCallback(() => {
    onSubmit({
      selected: scheduleChannels,
      selectedLabel: scheduleChannels.map((selected) => selected.name),
      message: message,
      isReadReceiptEnabled: isReadReceiptEnabled,
    });
  }, [onSubmit, scheduleChannels, message, isReadReceiptEnabled]);

  const onChangeMessage = useCallback((value: string) => setMessage(value), []);

  const onChangeSelectedScheduleChannel = useCallback(
    (channels: any[]) => {
      setScheduleChannels(() => {
        const mustSelecteAllSchedule = channels.some((c) => c.id === 1)
        if(mustSelecteAllSchedule){
          return scheduleChannelOptions.map((s) => s.value)
        }
        return channels
      });
    },
    [scheduleChannelOptions]
  );

  const onChangeReadReceiptEnabled = useCallback(
    (value: any, checked: boolean) => {
      track(checked ? MixpanelEvent.ReadReceiptEnabled : MixpanelEvent.ReadReceiptDisabled);
      setIsReadReceiptEnabled(checked);
    },
    [track]
  );

  return (
    <Modal open={isOpen} size="lg" backdrop={true} onClose={onClose}>
      <Modal.Title>Novo recado</Modal.Title>

      <Divider />

      <Modal.Body>
        <ChatTitleStyled>Escalas</ChatTitleStyled>

        <ChatSelectStyled
          data={scheduleChannelOptions}
          value={scheduleChannels}
          onChange={onChangeSelectedScheduleChannel}
          placeholder="Buscar escalas"
        />
        <VerticalSpacerStyled size={48} />

        <ChatTitleStyled>Recado</ChatTitleStyled>

        <ChatTextAreaStyled
          placeholder="Escreva um recado"
          onChange={onChangeMessage}
          value={message}
        />
        <VerticalSpacerStyled size={16} />

        <Render if={FLAG_ENABLE_INCLUDE_ATTACHMENTS}>
          <ChatButton isBold isUppercase>
            Incluir anexo
          </ChatButton>
          <VerticalSpacerStyled size={24} />
        </Render>

        <Render if={FLAG_ENABLE_IS_READ_RECEIPT_CHECKBOX}>
          <ChatCheckbox checked={isReadReceiptEnabled} onChange={onChangeReadReceiptEnabled}>
            Todos os leitores serão obrigados a marcar esta postagem como visualizada
          </ChatCheckbox>

          <VerticalSpacerStyled size={32} />
        </Render>

        <SubmitContainerStyled>
          <ChatButton
            isBold
            isUppercase
            onClick={onClickSubmit}
            disabled={!scheduleChannels.length || !message?.length}
          >
            Enviar
          </ChatButton>
        </SubmitContainerStyled>
        <VerticalSpacerStyled size={32} />
      </Modal.Body>
    </Modal>
  );
}
